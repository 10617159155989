<template>
  <div>
    <div class="acc_box">
      <div v-if="payee_list.length > 0">
        <div
          class="acc_item"
          v-for="(item, index) in payee_list"
          :key="index"
          :class="tx_id == item.tx_id ? 'active' : ''"
          @click="selectAccount(item)"
        >
          <div class="acc_top">
            <div>{{ $t("main.text82") }}{{ index + 1 }}</div>
            <div><i class="el-icon-edit" @click="to_edit(item)"></i></div>
          </div>
          <div class="acc_main">
            <div class="main_left">
              <div>{{ $t("main.text16") }}</div>
              <div>{{ $t("main.text17") }}</div>
              <div>{{ $t("main.text89") }}</div>
              <div>{{ $t("main.text90") }}</div>
              <div>{{ $t("main.text19") }}</div>
              <div>{{ $t("main.text20") }}</div>
            </div>
            <div class="main_left main_right">
              <div>{{ item.payee_country || "-" }}</div>
              <div>{{ item.payee_bank_name || "-" }}</div>
              <div>{{ item.payee_last_name || "-" }}</div>
              <div>{{ item.payee_account_name || "-" }}</div>
              <div>{{ item.payee_card_num || "-" }}</div>
              <div>{{ item.payee_swift_code || "-" }}</div>
            </div>
            <div class="check_box"><i class="el-icon-check"></i></div>
          </div>
        </div>
      </div>

      <div class="noData" v-else>{{ $t("main.text59") }}</div>

      <div
        v-if="!to_title"
        class="btm_btn"
        @click="$router.push('/add_account')"
      >
        {{ $t("main.text70") }}
      </div>
    </div>
  </div>
</template>

<script>
import { payee_list } from "../../../api/api";
export default {
  data() {
    return {
      to_title: this.$route.query.to_title,
      payee_list: [],
      tx_id: "",
    };
  },
  mounted() {
    if (this.to_title) {
      sessionStorage.setItem("hichat_title", this.$t("main.text83"));
      this.bus.$emit("set_title", this.$t("main.text83"));
    }
    this.get_list();
    if (
      JSON.parse(sessionStorage.getItem("edit_data")) &&
      JSON.parse(sessionStorage.getItem("edit_data")).tx_id
    ) {
      this.tx_id = JSON.parse(sessionStorage.getItem("edit_data")).tx_id;
    }
    
  },
  methods: {
    selectAccount(item) {
      this.tx_id = item.tx_id;
      sessionStorage.setItem("edit_data", JSON.stringify(item));
    },

    async get_list() {
      const res = await payee_list({ payee_type: 1 });
      if (res.data.code !== 200) {
        return this.msg("error", res.data.msg);
      }
      this.payee_list = res.data.data.list;
      if (this.tx_id == "" && res.data.data.list[0]) {
        this.tx_id = res.data.data.list[0].tx_id;
        sessionStorage.setItem(
          "edit_data",
          JSON.stringify(res.data.data.list[0])
        );
      }
    },

    to_edit(item) {
      this.tx_id = item.tx_id;
      sessionStorage.setItem("edit_data", JSON.stringify(item));
      setTimeout(() => {
        this.$router.push({ path: "/add_account", query: { is_edit: true } });
      }, 0);
    },
  },
};
</script>

<style scoped>
.acc_item {
  margin-bottom: 1.25rem;
}
.acc_top {
  box-sizing: border-box;
  padding: 0.62rem 1rem;
  border-radius: 0.375rem 0.375rem 0rem 0rem;
  background: #d6d6d6;
  color: #000;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.acc_top i {
  font-size: 1rem;
  vertical-align: middle;
}
.acc_main {
  background: #fff;
  border-radius: 0 0 0.375rem 0.375rem;
  display: flex;
  box-sizing: border-box;
  padding: 1.25rem;
  align-items: flex-end;
  position: relative;
}
.main_left {
  width: 40%;
  color: #666;
  font-size: 0.875rem;
  font-weight: 400;
}
.main_right {
  width: 60%;
  color: #353535;
  font-size: 0.875rem;
  font-weight: 600;
}
.main_left > div {
  margin-bottom: 0.75rem;
  height: 20px;
  line-height: 20px;
}
.main_left > div:last-child {
  margin-bottom: 0;
}

.active .acc_top {
  background: #2982d8;
  color: #fff;
}
.active .acc_main {
  border: 2px solid #2982d8;
  border-top: none;
}
.check_box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2rem;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
  border-radius: 1rem 0rem 0.25rem 0rem;
  background: #2982d8;
  display: none;
}
.check_box i {
  font-size: 1.5rem;
  vertical-align: middle;
  color: #fff;
}
.active .check_box {
  display: block;
}
.acc_box {
  background: #f5f6f7;
  height: calc(100vh - 3rem - 1.75rem - 4rem);
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  overflow: auto;
}
.btm_btn {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  background: #2982d8;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
.noData {
  margin-top: 6rem;
}
</style> 

